<template>
	<!-- 教师考勤-->
	<div class="teacherAttendance">
		<topBar ref='topBar' :tabs="['我的出勤','宝贝出勤',]" class="topBar" @tabNum='tabClick' >		
		</topBar>
		<div class="section">
			<!-- 我的出勤组件 -->
			<myAttence ref='myAttence' v-if="!tabNum" style="padding: 0 12px;"></myAttence>	
			<!-- 宝贝出勤 -->
			<babyAttence ref='babyAttence' v-else ></babyAttence>
		</div>
	</div>
</template>

<script>
	import {
		Image as VanImage,
		Tab,
		Tabs,
		Calendar
	} from 'vant'

	import topBar from '@/components/common/topBar/index.vue'
	import myAttence from './myAttence/index.vue'
	import babyAttence from './babyAttence/index.vue'


	export default {
		name: 'teacherAttendance',
		components: {
			topBar,
			myAttence,
			babyAttence,
			[VanImage.name]: VanImage,
			[Tab.name]: Tab,
			[Tabs.name]: Tabs,
			[Calendar.name]: Calendar
		},
		data() {
			return {
				tabNum: 0,
			}
		},
		mounted() {

		},
		methods: {
			tabClick(index) {
				this.tabNum = index
				// 切换,宝贝考勤默认展示签到组件
				this.$refs.babyAttence.isShowSign = false
			},
		}
	}
</script>

<style lang="less">
	.teacherAttendance {
		//padding: 10px 12px;
		height: 100%;
		display: flex;
		flex-direction: column;
		.topBar {
			font-size: 14px;
			width: 72%;
			margin: 10px auto 0px;
		}
		.section{
			flex: 1;
			margin-top: 10px;
		}
	}
</style>
